@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Cabin:400,600,700");

* {
  /*font-family: 'Marine';*/
  font-family: "Helvetica", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cabin", sans-serif !important;
}

th:first-child,
td:first-child {
  border-radius: 10px 0 0 0px;
}

th:last-child,
td:last-child {
  border-radius: 0 10px 0px 0;
}
.basic-mobile {
  margin-top: 10px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  background-image: none;
  transition: all 0.15s ease 0s;
  box-shadow: none;
  outline: none;
}
.mobile-single .select__control,
.select__single {
  border: none;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  border-radius: 9px;
  background-color: #ffffff;
  color: #000000 !important;
  z-index: 99999999 !important;
}
.basic-single {
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  color: #4a5568;
  background-image: none;
  transition: all 0.15s ease 0s;
  box-shadow: none;
  outline: none;
}

.basic-single .select__control,
.select__single {
  border: none;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  border-radius: 9px;
  background-color: #00263e;
  color: #fff !important;
}

.basic-single .select__single-value {
  color: #fff !important;
}

.select__indicator-separator {
  display: none;
}

.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  margin-top: 2px;
  width: 200px;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f4f4f4;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

.slider {
  position: relative;
  width: 200px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #00263e;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #f4f4f4;
  z-index: 2;
}
